import React from "react"
import PropTypes from "prop-types"

import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import Section from "../Section/Section"

import "./Layout.css"

const Layout = ({ children }) => (
  <div id="root">
    <main>
      <Header />
      <Section>{children}</Section>
    </main>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
