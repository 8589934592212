import React from "react"

import "./Footer.css"

const Footer = () => {
  return (
    <footer>
      <div>
        <div id="img-wrapper">
          <a href="https://kfc.pl" target="_blank" rel="noopener noreferrer">
            <img
              src="https://eco.kfc.pl/static/img/svg/kfc.svg"
              alt="logo"
              height="120"
              width="120"
            />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
