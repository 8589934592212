import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./Header.css"

const activeStyle = {
  color: "white",
}

const Header = () => (
  <header>
    <nav>
      <ul>
        <li>
          <Link to="/" activeStyle={activeStyle}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" activeStyle={activeStyle}>
            About
          </Link>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
